@import "../../assets/variables/variables.scss";

.footer {
    width: calc(100% - 24px);
    padding: 8px 12px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    background-color: map-get($theme-colors, "jaune");
    p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 16px;
        margin: 0;
        color: #fff;
    }
}
