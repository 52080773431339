.about {
    width: 100%;
    min-height: calc(100vh - 102px);
    &-inner {
        width: calc(100% - 60px);
        max-width: 1260px;
        margin: 0 auto;
        display: flex;
        padding: 70px 30px 50px 30px;
        flex-direction: column;
        h1 {
            font-size: 34px;
            line-height: 40px;
            margin: 0 0 20px 0;
            display: grid;
            grid-template-columns: 1fr max-content 1fr;
            grid-template-rows: 27px 0;
            grid-gap: 20px;
            align-items: center;
            &:after,
            &:before {
                content: " ";
                display: block;
                border-bottom: 1px solid #ccc;
                border-top: 1px solid #ccc;
                height: 5px;
                background-color: #fff;
            }
        }
        p {
            font-size: 16px;
            line-height: 22px;
            margin: 0 0 20px 0;
        }
        ul {
            padding: 0 0 0 20px;
            margin: 0 0 20px 0;
            li {
                list-style: circle;
                margin: 0 0 10px 0;
                line-height: 20px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
