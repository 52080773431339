@import "../../assets/variables/variables.scss";

/* Big screen */
$parametersWidth: 450px;
/* Desktop */
$desktopParametersWidth: 350px;
/* Tablet */
$tabletParametersWidth: 100%;

.home {
    width: 100%;
    display: flex;
    flex-direction: row;
    &-inner {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 0;
        margin: 0;
        &_body {
            width: 100%;
            display: flex;
            flex-direction: row;
            padding: 0;
            margin: 0;
            height: calc(100vh - 102px);
            overflow-x: hidden;
            position: relative;
            &.openParameters {
                &.openResults {
                    .home-inner_body_viewer {
                        .home-inner_body_viewer_footer {
                            width: calc(100% - #{$parametersWidth});
                            @media only screen and (max-width: 1300px) {
                                width: calc(100% - #{$desktopParametersWidth});
                            }
                        }
                    }
                }
                .home-inner_body_viewer {
                    .leaflet-container {
                        .leaflet-top.leaflet-left {
                            left: $parametersWidth;
                            @media only screen and (max-width: 1300px) {
                                left: $desktopParametersWidth;
                            }
                        }
                        .leaflet-bottom.leaflet-left {
                            left: $parametersWidth;
                            @media only screen and (max-width: 1300px) {
                                left: $desktopParametersWidth;
                            }
                        }
                        .leaflet-top.leaflet-right {
                            right: unset !important;
                            left: calc(#{$parametersWidth} + 10px) !important;
                            @media only screen and (max-width: 1300px) {
                                left: calc(#{$desktopParametersWidth} + 10px) !important;
                            }
                        }
                    }
                    .legend {
                        left: calc(#{$parametersWidth} + 10px);
                        @media only screen and (max-width: 1300px) {
                            left: calc(#{$desktopParametersWidth} + 10px);
                            -webkit-transition: all 0.3s ease-in-out;
                            -moz-transition: all 0.3s ease-in-out;
                            -o-transition: all 0.3s ease-in-out;
                            transition: all 0.3s ease-in-out;
                        }
                    }
                    .home-inner_body_viewer_footer {
                        width: calc(100% - #{$parametersWidth});
                        position: relative;
                        left: $parametersWidth;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        @media only screen and (max-width: 1300px) {
                            width: calc(100% - #{$desktopParametersWidth});
                            left: $desktopParametersWidth;
                        }
                    }
                }
            }
            &.openResults {
                &.openParameters {
                    .home-inner_body_viewer {
                        .home-inner_body_viewer_footer {
                            width: calc(100% - #{$parametersWidth});
                            @media only screen and (max-width: 1300px) {
                                width: calc(100% - #{$desktopParametersWidth});
                            }
                        }
                    }
                }
                .home-inner_body_viewer {
                    .home-inner_body_viewer_footer {
                        width: calc(100% - #{$parametersWidth});
                    }
                }
            }
            &_parameters {
                width: $parametersWidth;
                height: 100%;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 0;
                left: 0;
                /* justify-content: center; */
                padding: 0;
                background-color: #fff;
                z-index: 20;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                background-color: #fff;
                @media only screen and (max-width: 1300px) {
                    width: $desktopParametersWidth;
                }
                &.close {
                    left: -$parametersWidth;
                    @media only screen and (max-width: 1300px) {
                        left: -$desktopParametersWidth;
                    }
                    .home-inner_body_parameters_collapse {
                        button {
                            svg {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                form {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                }
                &_content {
                    width: 100%;
                    height: calc(100% - 79px);
                    display: flex;
                    flex-direction: column;
                    margin: 0;
                    padding: 0;
                    overflow-x: hidden;
                    overflow-y: auto;
                    &_item {
                        width: calc(100% - 40px);
                        padding: 0 20px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        flex-wrap: wrap;
                        margin: 0;
                        &:first-child {
                            padding-top: 20px;
                            margin-bottom: 15px;
                        }
                        &:last-child {
                            padding-bottom: 20px;
                        }
                        h2 {
                            margin: 0;
                            font-size: 26px;
                            font-family: "Roboto", sans-serif;
                            color: #000;
                            @media only screen and (max-width: 1300px) {
                                font-size: 20px;
                            }
                        }
                        h3 {
                            font-size: 20px;
                            margin: 0;
                            font-family: "Roboto", sans-serif;
                            color: #000;
                            @media only screen and (max-width: 1300px) {
                                font-size: 18px;
                            }
                        }
                        p {
                            width: auto;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 0;
                            input {
                                width: 40px;
                                min-width: 30px;
                                font-family: "Roboto", sans-serif;
                                display: inline-flex;
                                border: solid 1px #000;
                                padding: 2px 10px;
                                border-radius: 5px;
                                margin: 0 8px 0 0;
                                text-align: center;
                                @media only screen and (max-width: 1300px) {
                                    min-width: 25px;
                                    width: 35px;
                                }
                                &:focus {
                                    outline: 0;
                                    box-shadow: 0 0 0 0.15rem lighten(map-get($theme-colors, "jaune"), 20%);
                                    border-color: map-get($theme-colors, "jaune");
                                }
                            }
                            span {
                                margin: 0;
                                display: block;
                                font-size: 14px;
                                line-height: 16px;
                                font-family: "Roboto", sans-serif;
                                border: none;
                                padding: 0;
                                @media only screen and (max-width: 1300px) {
                                    font-size: 13px;
                                }
                            }
                        }
                        .MuiSvgIcon-root.info {
                            font-size: 18px;
                        }
                        &.title {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 10px 0 10px 0;
                            &.except {
                                margin-top: 25px;
                            }
                            .home-inner_body_parameters_content_item_left {
                                width: calc(100% - 30px);
                            }
                            .home-inner_body_parameters_content_item_right {
                                width: 30px;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;
                                svg {
                                    font-size: 24px;
                                }
                            }
                        }
                        &.radio-group {
                            margin: 10px 0 20px 0;
                            p {
                                @media only screen and (max-width: 1300px) {
                                    font-size: 14px;
                                }
                            }
                            .home-inner_body_parameters_content_item_left {
                                width: 120px;
                                @media only screen and (max-width: 1300px) {
                                    width: 100px;
                                }
                            }
                            .home-inner_body_parameters_content_item_right {
                                width: calc(100% - 120px);
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-end;
                                @media only screen and (max-width: 1300px) {
                                    width: calc(100% - 100px);
                                }
                                .MuiFormControl-root {
                                    .MuiFormGroup-root {
                                        display: flex;
                                        flex-direction: row;
                                        .radio-group-wrapper {
                                            width: auto;
                                            display: flex;
                                            flex-direction: row;
                                            align-items: center;
                                            margin-left: 20px;
                                            &:first-child {
                                                margin-left: 0;
                                            }
                                            @media only screen and (max-width: 1300px) {
                                                margin-left: 15px;
                                            }
                                            .MuiFormControlLabel-root {
                                                margin-right: 10px;
                                                .MuiButtonBase-root {
                                                    &.Mui-checked {
                                                        color: map-get($theme-colors, "jaune");
                                                    }
                                                }
                                            }
                                            .MuiRadio-root {
                                                padding: 6px;
                                            }
                                            .MuiTypography-root {
                                                font-size: 14px;
                                                font-family: "Roboto", sans-serif;
                                                @media only screen and (max-width: 1300px) {
                                                    font-size: 12px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .MuiFormControlLabel-root {
                            margin-right: 10px;
                            /* .MuiCheckbox-root {
                        } */
                            .MuiCheckbox-root {
                                &.Mui-checked {
                                    color: map-get($theme-colors, "jaune");
                                }
                            }
                            .MuiTypography-root {
                                font-size: 14px;
                                line-height: 16px;
                                font-family: "Roboto", sans-serif;
                                @media only screen and (max-width: 1300px) {
                                    font-size: 13px;
                                }
                            }
                        }
                        &_left {
                            width: 50%;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            p {
                                margin: 10px 0;
                            }
                            svg {
                                margin-left: 10px;
                            }
                        }
                        &_right {
                            width: 50%;
                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                        }
                        &_full {
                            width: 100%;
                            &.slider {
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                margin: 15px 0 0 0;
                                p {
                                    width: 110px;
                                    display: flex;
                                    font-family: "Roboto", sans-serif;
                                    font-weight: 600;
                                    font-size: 20px;
                                    line-height: 20px;
                                    @media only screen and (max-width: 1300px) {
                                        font-size: 16px;
                                    }
                                    &:first-child {
                                        justify-content: flex-start;
                                    }
                                    &:last-child {
                                        justify-content: flex-end;
                                    }
                                }
                                .MuiSlider-colorPrimary {
                                    .MuiSlider-rail {
                                        color: rgba(0, 0, 0, 0.2);
                                        height: 6px;
                                    }
                                    .MuiSlider-track {
                                        height: 6px;
                                    }
                                    .MuiSlider-thumb {
                                        width: 20px;
                                        height: 20px;
                                    }
                                    .MuiSlider-track,
                                    .MuiSlider-thumb {
                                        color: map-get($theme-colors, "jaune");
                                    }
                                }
                            }
                            &.checkbox-group {
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                            }
                        }
                    }
                    .divider {
                        width: calc(100% - 40px);
                        height: 1px;
                        background-color: rgba(0, 0, 0, 0.2);
                        display: flex;
                        flex-direction: row;
                        padding: 1px 0 0 0;
                        margin: 15px auto;
                    }
                }
                &_footer {
                    width: calc(100% - 40px);
                    padding: 0 20px;
                    overflow-x: auto;
                    height: 79px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin: 0;
                    /*position: relative;
                     top: 2px;*/
                    border-top: solid 1px #f2f2f2;
                    button {
                        background-color: map-get($theme-colors, "jaune");
                        color: #fff;
                        text-transform: uppercase;
                        font-size: 14px;
                        padding: 5px 10px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-family: "Roboto", sans-serif;
                        font-weight: 600;
                        &:hover {
                            background-color: darken(map-get($theme-colors, "jaune"), 12%);
                        }
                        &.Mui-disabled {
                            background-color: darken(map-get($theme-colors, "jaune"), 15%);
                            color: rgba(255, 255, 255, 0.5);
                            span {
                                color: rgba(255, 255, 255, 0.5) !important;
                            }
                            .MuiCircularProgress-root {
                                color: #fff !important;
                            }
                        }
                        .MuiCircularProgress-root {
                            width: 20px !important;
                            height: 20px !important;
                            color: #ffffff !important;
                            margin-left: 10px;
                        }
                        span {
                            color: #fff !important;
                        }
                        .magic {
                            margin-right: 5px;
                            font-size: 20px;
                        }
                    }
                }
                &_collapse {
                    position: absolute;
                    top: 180px;
                    right: -33px;
                    transform: translateY(-50%);
                    button {
                        width: 34px;
                        height: 34px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        background-color: #fff;
                        border: none;
                        padding: 5px;
                        border: solid 2px rgba(0, 0, 0, 0.15);
                        border-left: none;
                        border-radius: 0 5px 5px 0;
                        svg {
                            font-size: 16px;
                        }
                    }
                }
            }
            &_viewer {
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 0;
                margin: 0;
                position: relative;
                z-index: 2;
                &_content {
                    width: 100%;
                    height: calc(100% - 79px);
                    display: flex;
                    position: relative;
                    .map {
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        background-color: #f0f0f0;
                        #map {
                            width: 100%;
                            height: 100%;
                            &.leaflet-container {
                                width: 100%;
                                height: 100%;
                                .leaflet-control-container {
                                    .leaflet-top.leaflet-left {
                                        -webkit-transition: all 0.3s ease-in-out;
                                        -moz-transition: all 0.3s ease-in-out;
                                        -o-transition: all 0.3s ease-in-out;
                                        transition: all 0.3s ease-in-out;
                                    }
                                    .leaflet-top.leaflet-right {
                                        right: unset;
                                        left: 10px;
                                        top: 75px;
                                        -webkit-transition: all 0.3s ease-in-out;
                                        -moz-transition: all 0.3s ease-in-out;
                                        -o-transition: all 0.3s ease-in-out;
                                        transition: all 0.3s ease-in-out;
                                    }
                                }
                            }
                        }
                    }
                    .legend {
                        position: absolute;
                        left: 10px;
                        bottom: 50px;
                        background-color: #fff;
                        padding: 15px;
                        border-radius: 5px;
                        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.15);
                        z-index: 2;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        div {
                            -webkit-transition: all 0.3s ease-in-out;
                            -moz-transition: all 0.3s ease-in-out;
                            -o-transition: all 0.3s ease-in-out;
                            transition: all 0.3s ease-in-out;
                            h3 {
                                font-size: 16px;
                                line-height: 20px;
                                margin: 0 0 10px 0;
                                //font-family: "Century Gothic Bold", sans-serif;
                                font-family: "Roboto", sans-serif;
                            }
                            ul {
                                width: auto;
                                padding: 0;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                margin: 5px 0 0 0;
                                li {
                                    list-style: none;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                    font-size: 14px;
                                    //font-family: "Century Gothic Bold", sans-serif;
                                    font-family: "Roboto", sans-serif;
                                    font-weight: 500;
                                    span {
                                        width: 16px;
                                        height: 16px;
                                        display: block;
                                        margin-right: 8px;
                                        border: solid 1px #f2f2f2;
                                    }
                                }
                            }
                            &.all {
                                ul {
                                    &:nth-child(2) {
                                        li span {
                                            background-color: #ffffe5;
                                        }
                                    }
                                    &:nth-child(3) {
                                        li span {
                                            background-color: #fff7bc;
                                        }
                                    }
                                    &:nth-child(4) {
                                        li span {
                                            background-color: #fee391;
                                        }
                                    }
                                    &:nth-child(5) {
                                        li span {
                                            background-color: #fec44f;
                                        }
                                    }
                                    &:nth-child(6) {
                                        li span {
                                            background-color: #fe9929;
                                        }
                                    }
                                    &:nth-child(7) {
                                        li span {
                                            background-color: #ec7014;
                                        }
                                    }
                                    &:nth-child(8) {
                                        li span {
                                            background-color: #cc4c02;
                                        }
                                    }
                                    &:nth-child(9) {
                                        li span {
                                            background-color: #8c2d04;
                                        }
                                    }
                                }
                            }
                            &.difference {
                                ul {
                                    &:nth-child(2) {
                                        li span {
                                            background-color: #ffffe5;
                                        }
                                    }
                                    &:nth-child(3) {
                                        li span {
                                            background-color: #f7fcb9;
                                        }
                                    }
                                    &:nth-child(4) {
                                        li span {
                                            background-color: #d9f0a3;
                                        }
                                    }
                                    &:nth-child(5) {
                                        li span {
                                            background-color: #addd8e;
                                        }
                                    }
                                    &:nth-child(6) {
                                        li span {
                                            background-color: #78c679;
                                        }
                                    }
                                    &:nth-child(7) {
                                        li span {
                                            background-color: #41ab5d;
                                        }
                                    }
                                    &:nth-child(8) {
                                        li span {
                                            background-color: #238443;
                                        }
                                    }
                                    &:nth-child(9) {
                                        li span {
                                            background-color: #005a32;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                &_footer {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 0;
                    height: 79px;
                    border-top: solid 1px #f2f2f2;
                    /* p {
                        font-size: 15px;
                        margin-right: 20px;
                        font-family: "Roboto", sans-serif;
                        font-weight: 600;
                    } */
                    &.hideRight {
                        .home-inner_body_viewer_footer_left {
                            width: 100%;
                        }
                        .home-inner_body_viewer_footer_right {
                            width: 0;
                            position: relative;
                            right: -$parametersWidth;
                            @media only screen and (max-width: 1300px) {
                                right: -$desktopParametersWidth;
                            }
                        }
                    }
                    &_left {
                        width: calc(100% - #{$parametersWidth});
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        padding: 0 20px;
                        overflow-x: auto;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        @media only screen and (max-width: 1300px) {
                            width: calc(100% - #{$desktopParametersWidth});
                        }
                        button {
                            background-color: map-get($theme-colors, "jaune");
                            color: #fff;
                            text-transform: uppercase;
                            font-size: 14px;
                            padding: 5px 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin-right: 10px;
                            font-family: "Roboto", sans-serif;
                            font-weight: 600;
                            min-width: 130px;
                            border: solid 1px map-get($theme-colors, "jaune");
                            //font-family: "Century Gothic Bold", sans-serif;
                            &.active {
                                background-color: transparent;
                                color: map-get($theme-colors, "jaune");
                            }
                            &:hover {
                                background-color: map-get($theme-colors, "jaune");
                                color: #fff;
                            }
                            svg {
                                margin-right: 5px;
                                font-size: 20px;
                            }
                            &.Mui-disabled {
                                color: rgba(255, 255, 255, 0.5);
                            }
                        }
                    }
                    &_right {
                        width: $parametersWidth;
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                        padding: 0 20px;
                        overflow-x: auto;
                        -webkit-transition: all 0.3s ease-in-out;
                        -moz-transition: all 0.3s ease-in-out;
                        -o-transition: all 0.3s ease-in-out;
                        transition: all 0.3s ease-in-out;
                        @media only screen and (max-width: 1300px) {
                            width: $desktopParametersWidth;
                        }
                        Button {
                            background-color: map-get($theme-colors, "jaune");
                            color: #fff;
                            text-transform: uppercase;
                            font-size: 14px;
                            padding: 5px 10px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            font-family: "Roboto", sans-serif;
                            font-weight: 600;
                            &:hover {
                                background-color: darken(map-get($theme-colors, "jaune"), 12%);
                            }
                            &.Mui-disabled {
                                background-color: darken(map-get($theme-colors, "jaune"), 15%);
                                color: rgba(255, 255, 255, 0.5);
                                span {
                                    color: rgba(255, 255, 255, 0.5) !important;
                                }
                                .MuiCircularProgress-root {
                                    color: #fff !important;
                                }
                            }
                            .MuiCircularProgress-root {
                                width: 20px !important;
                                height: 20px !important;
                                color: #ffffff !important;
                                margin-left: 10px;
                            }
                            span {
                                color: #fff !important;
                            }
                        }
                    }
                }
            }
            &_results {
                width: $parametersWidth;
                height: calc(100% - 80px);
                display: flex;
                flex-direction: column;
                margin: 0;
                padding: 0;
                position: absolute;
                top: 0;
                right: 0;
                z-index: 20;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                background-color: #fff;
                @media only screen and (max-width: 1300px) {
                    width: $desktopParametersWidth;
                }
                &.close {
                    right: -$parametersWidth;
                    @media only screen and (max-width: 1300px) {
                        right: -$desktopParametersWidth;
                    }
                    .home-inner_body_results_collapse {
                        button {
                            svg {
                                transform: rotate(180deg);
                            }
                        }
                    }
                }
                &_content {
                    width: calc(100% - 40px);
                    margin: 0;
                    padding: 20px;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    overflow-x: hidden;
                    overflow-y: auto;
                    .divider {
                        width: 100%;
                        height: 1px;
                        background-color: rgba(0, 0, 0, 0.2);
                        display: flex;
                        flex-direction: row;
                        padding: 0;
                        margin: 10px auto 15px auto;
                    }
                    &_item {
                        width: 100%;
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        align-items: center;
                        margin-bottom: 10px;
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                        &.right-text {
                            justify-content: flex-end;
                        }
                        &.title {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 0 0 10px 0;
                            .home-inner_body_results_content_item_left {
                                width: auto;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                svg {
                                    width: 24px;
                                    display: flex;
                                    flex-direction: row;
                                    justify-content: flex-end;
                                    align-items: center;
                                    margin-left: 7px;
                                }
                            }
                            .home-inner_body_results_content_item_right {
                                p {
                                    margin: 0;
                                }
                            }
                        }
                        &.no-light {
                            margin-bottom: 0;
                        }
                        h2 {
                            margin: 0;
                            font-size: 26px;
                            font-family: "Roboto", sans-serif;
                            color: #000;
                            @media only screen and (max-width: 1300px) {
                                font-size: 20px;
                            }
                        }
                        h3 {
                            font-size: 20px;
                            margin: 0;
                            font-family: "Roboto", sans-serif;
                            color: #000;
                            @media only screen and (max-width: 1300px) {
                                font-size: 16px;
                            }
                        }
                        p {
                            width: auto;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 0;
                            font-size: 14px;
                            span {
                                margin: 0;
                                display: block;
                                font-size: 14px;
                                line-height: 16px;
                                font-weight: 600;
                                font-family: "Roboto", sans-serif;
                                border: none;
                                padding: 0;
                                margin-right: 5px;
                                @media only screen and (max-width: 1300px) {
                                    font-size: 13px;
                                }
                            }
                        }
                        ul {
                            padding: 0;
                            margin: 0;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            &:first-child {
                                padding: 0 10px 0 0;
                            }
                            &:last-child {
                                padding: 0 0 0 10px;
                                li {
                                    &:first-child {
                                        font-weight: 600;
                                        margin-right: 5px;
                                    }
                                }
                            }
                            li {
                                list-style: none;
                                margin-right: 10px;
                                font-size: 14px;
                                display: flex;
                                flex-direction: row;
                                align-items: center;
                                &:last-child {
                                    margin-right: 0;
                                }
                                span {
                                    margin-right: 10px;
                                }
                                svg {
                                    font-size: 18px;
                                }
                            }
                        }
                        &_left {
                            width: auto;
                        }
                        &_right {
                            width: auto;
                            p {
                                span {
                                    &.over {
                                        color: red;
                                    }
                                    &.great {
                                        color: green;
                                    }
                                }
                            }
                        }
                    }
                    .no-light-wrap {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        margin: 0 0 10px 0;
                        &:first-child {
                            p {
                                margin-top: 10px;
                            }
                        }
                        &:last-child {
                            margin-bottom: 0;
                        }
                        p {
                            margin: 0;
                            font-size: 16px;
                            margin: 0 0 10px 0;
                        }
                        ul {
                            li {
                                margin: 0 0 4px 0;
                            }
                        }
                    }
                }
                &_footer {
                    width: calc(100% - 40px);
                    padding: 0 20px;
                    height: 79px;
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-end;
                    align-items: center;
                    margin: 0;
                    /*  position: relative;
                    top: 2px; */
                    border-top: solid 1px #f2f2f2;
                    Button {
                        background-color: map-get($theme-colors, "jaune");
                        color: #fff;
                        text-transform: uppercase;
                        font-size: 14px;
                        padding: 5px 10px;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        font-family: "Roboto", sans-serif;
                        font-weight: 600;
                        &:hover {
                            background-color: darken(map-get($theme-colors, "jaune"), 12%);
                        }
                        &.Mui-disabled {
                            background-color: darken(map-get($theme-colors, "jaune"), 15%);
                            color: rgba(255, 255, 255, 0.5);
                            span {
                                color: rgba(255, 255, 255, 0.5) !important;
                            }
                            .MuiCircularProgress-root {
                                color: #fff !important;
                            }
                        }
                        .MuiCircularProgress-root {
                            width: 20px !important;
                            height: 20px !important;
                            color: #ffffff !important;
                            margin-left: 10px;
                        }
                        span {
                            color: #fff !important;
                        }
                    }
                }
                &_collapse {
                    position: absolute;
                    top: 10px;
                    left: -34px;
                    z-index: 20;
                    button {
                        width: 34px;
                        height: 34px;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        background-color: #fff;
                        border: none;
                        padding: 5px;
                        border: solid 2px rgba(0, 0, 0, 0.15);
                        border-right: none;
                        border-radius: 5px 0 0 5px;
                        svg {
                            font-size: 16px;
                            -webkit-transition: all 0.2s ease-in-out;
                            -moz-transition: all 0.2s ease-in-out;
                            -o-transition: all 0.2s ease-in-out;
                            transition: all 0.2s ease-in-out;
                        }
                    }
                }
            }
        }
    }
}
