@import "../../assets/variables/variables.scss";

.not-found {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &-inner {
        width: 400px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        p {
            color: #000;
            margin: 0;
            font-size: 20px;
            line-height: 24px;
        }
        a {
            display: inline-flex;
            margin: 15px 0 0 0;
            text-decoration: none;
            padding: 8px 20px;
            border-radius: 5px;
            color: #fff;
            background-color: map-get($theme-colors, "jaune");
            -webkit-transition: all 0.3s ease-in-out;
            -moz-transition: all 0.3s ease-in-out;
            -o-transition: all 0.3s ease-in-out;
            transition: all 0.3s ease-in-out;
            &:hover {
                background-color: darken(map-get($theme-colors, "jaune"), 10%);
            }
        }
    }
}
