@import "../../assets/variables/variables.scss";

.login {
    width: 100%;
    height: 100vh;
    display: flex;
    padding: 0;
    margin: 0;
    flex-direction: row;
    &-left {
        width: 600px;
        height: 100%;
        background-color: map-get($theme-colors, "jaune");
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        &-logo {
            width: 100%;
            max-width: 280px;
            background-color: #fff;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 30px;
            box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
                0px 3px 5px rgba(0, 0, 0, 0.2);
            border-radius: 7px;
            &-item {
                &:nth-child(1) {
                    width: 50%;
                    padding: 0 12px 0 0;
                    border-right: 1px solid #00000099;
                }
                &:nth-child(2) {
                    width: 50%;
                    padding: 0 0 0 12px;
                }
                img {
                    width: 100%;
                    align-items: center;
                }
            }
        }
        &-cizo-text {
            h1 {
                color: #fff;
                margin: 25px 0;
                font-size: 48px;
                line-height: 48px;
                font-weight: 600;
            }
        }
        &-form {
            margin: 0;
            background-color: #fff;
            padding: 20px;
            box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12),
                0px 3px 5px rgba(0, 0, 0, 0.2);
            border-radius: 7px;
            h2 {
                width: 100%;
                text-align: center;
                margin: 0;
                font-family: "Roboto", sans-serif;
                font-weight: 600;
                font-size: 20px;
                line-height: 22px;
            }
            form {
                width: 100%;
                min-width: 300px;
                margin: 20px 0 0 0;
                .form-group {
                    width: 100%;
                    margin: 0 0 30px 0;
                    position: relative;
                    &:last-child {
                        margin: 0;
                    }
                    .MuiFormControl-root {
                        width: 100%;
                        &.is-invalid {
                            .MuiFormLabel-root {
                                color: #ff4444 !important;
                                &.Mui-focused {
                                    color: #ff4444 !important;
                                }
                            }
                            .MuiOutlinedInput-root {
                                .MuiOutlinedInput-notchedOutline {
                                    border-color: #ff4444 !important;
                                }
                            }
                        }
                        .MuiInputLabel-root {
                            font-family: "Roboto", sans-serif;
                            font-weight: normal;
                            &.Mui-focused {
                                color: rgba(0, 0, 0, 0.6) !important;
                            }
                        }
                        .MuiOutlinedInput-root {
                            &.Mui-focused {
                                .MuiOutlinedInput-notchedOutline {
                                    border-color: rgba(0, 0, 0, 0.6);
                                }
                            }
                            .MuiOutlinedInput-input {
                                font-family: "Roboto", sans-serif;
                                font-weight: normal;
                            }
                        }
                        .MuiOutlinedInput-notchedOutline {
                            border-color: rgba(0, 0, 0, 0.4);
                        }
                        .MuiInputLabel-root {
                            color: #000;
                        }
                        &.mdp {
                            .MuiInputLabel-root {
                                transform: translate(14px, 9px) scale(1);
                            }
                            .MuiInputLabel-shrink {
                                background-color: #fff;
                                padding-right: 8px;
                                transform: translate(14px, -9px) scale(0.75);
                            }
                        }
                    }
                    &.submit {
                        width: 100%;
                        display: flex;
                        justify-content: center;
                        button {
                            background-color: map-get($theme-colors, "jaune") !important;
                            color: #fff;
                            box-shadow: none;
                            font-family: "Roboto", sans-serif;
                            font-weight: 600;
                            &:hover {
                                background-color: darken(map-get($theme-colors, "jaune"), 15%) !important;
                            }
                            &:disabled {
                                background-color: darken(map-get($theme-colors, "jaune"), 15%);
                                color: rgba(255, 255, 255, 0.5);
                            }
                            .MuiCircularProgress-root {
                                width: 20px !important;
                                height: 20px !important;
                                color: #ffffff !important;
                                margin-left: 10px;
                            }
                            span {
                                color: #fff !important;
                            }
                        }
                    }
                    .error {
                        color: #f44336;
                        margin: 0;
                        padding: 0;
                        position: absolute;
                        bottom: -20px;
                        left: 0;
                        font-weight: 600;
                        font-family: "Roboto", sans-serif;
                        font-size: 0.75rem;
                        line-height: 1.66;
                        letter-spacing: 0.03333em;
                        text-align: left;
                    }
                }
            }
        }
        &-footer {
            position: absolute;
            bottom: 28px;
            left: 50%;
            transform: translateX(-50%);
            p {
                font-style: normal;
                font-weight: bold;
                font-size: 14px;
                line-height: 16px;
                color: #fff;
                margin: 0;
            }
        }
    }
    &-right {
        width: calc(100% - 600px);
        height: 100%;
        display: flex;
        background: url("../../assets/images/login-background-image.jpg") center right no-repeat;
        background-size: cover;
        position: relative;
        &:before {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.5);
        }
    }
}
