@import "../../assets/variables/variables.scss";

.header {
    width: calc(100% - 40px);
    background-color: map-get($theme-colors, "jaune");
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    &_left {
        width: auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        img {
            width: auto;
            height: 50px;
            margin: 0;
            cursor: pointer;
        }
        .divider {
            display: flex;
            width: 1px;
            height: 50px;
            margin: 0 10px;
            background-color: #00000099;
        }
    }
    &_right {
        width: calc(100% - 60px);
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 0 0 0 15px;
        &_text {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            h1 {
                font-family: "Roboto", sans-serif;
                font-size: 36px;
                line-height: 36px;
                margin: 0;
                font-weight: 600;
                color: #fff;
            }
            .divider {
                display: flex;
                width: 1px;
                height: 20px;
                margin: 0 8px;
                background-color: #fff;
            }
            p {
                font-family: "Roboto", sans-serif;
                font-size: 18px;
                line-height: 24px;
                color: #fff;
                margin: 0;
            }
        }
        &_logout {
            display: flex;
            flex-direction: row;
            align-items: center;
            a {
                color: #fff;
                text-decoration: none;
                margin-right: 20px;
                &.active {
                    text-decoration: underline;
                }
            }
            button {
                border: solid 1px #fff;
                padding: 5px 10px;
                color: map-get($theme-colors, "jaune");
                text-transform: none;
                -webkit-transition: all 0.3s ease-in-out;
                -moz-transition: all 0.3s ease-in-out;
                -o-transition: all 0.3s ease-in-out;
                transition: all 0.3s ease-in-out;
                background-color: #fff;
                &:hover {
                    background-color: transparent;
                    color: #fff;
                }
            }
        }
    }
}
