@font-face {
  font-family: "Century Gothic Regular";
  src: local("CenturyGothicRegular"), url("./assets/fonts//CenturyGothic.woff2") format("woff2");
}

@font-face {
  font-family: "Century Gothic Bold";
  src: local("CenturyGothicRegular"), url("./assets/fonts//CenturyGothic-Bold.woff2") format("woff2");
}

@font-face {
  font-family: "Century Gothic Italic";
  src: local("CenturyGothicRegular"), url("./assets/fonts//CenturyGothic-Italic.woff2") format("woff2");
}

body {
  font-family: "Roboto", sans-serif;
  /* font-family: "Century Gothic Regular", sans-serif; */
}
