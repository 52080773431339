.MuiSnackbar-root {
    &.error {
        .MuiPaper-root {
            background-color: rgba(211, 47, 47, 1);
        }
    }
    &.warning {
        .MuiPaper-root {
            background-color: rgba(245, 124, 0, 1);
        }
    }
    &.info {
        .MuiPaper-root {
            background-color: rgba(2, 136, 209, 1);
        }
    }
    &.success {
        .MuiPaper-root {
            background-color: rgba(56, 142, 60, 1);
        }
    }
}
